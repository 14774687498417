@import '~@bayada/assets/scss/abstracts/breakpoints.module';

img {
  width: 100%;
  height: auto;
}

.preview-mode-alert {
  min-height: 35px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--primary-shade);
  color: white;
  z-index: 1000;
  text-align: center;
  cursor: pointer;

  button {
    text-decoration: underline;
  }
}

.min-h-dvh {
  min-height: 100dvh;
}

.min-w-dvw {
  min-width: 100dvw;
}

.max-h-dvh {
  max-height: 100dvh;
}

.max-w-dvw {
  max-width: 100dvw;
}

// Styles that modify website UI based on Contentful Preview mode

.inspector-mode {
  padding-top: 80px;
  position: relative;

  @include breakpoint-down(md) {
    padding-top: 80px;
  }

  .app-nav-wrap {
    top: 40px;
    width: calc(100vw - 17px);
  }

  .app-page {
    min-height: 100vh;
    padding-top: 1px !important;

    .page-template-wrapper {
      &.home {
        margin-top: 80px;
      }
    }
  }

  .app-menu {
    .app-menu-body {
      .app-menu-body-nav {
        margin-top: 140px;
      }
    }
  }

  .primary-navbar {
    margin-top: 40px;
  }
}

.app-page {
  min-height: auto;

  &.is-loading {
    .section {
      min-height: 100% !important;
    }
  }
}
