/**
 * IMPORTANT
 * 
 * Breakpoint variables are included in this file for the design system followed by bayada.com
 * The breakpoint variables declared below apply for the entire application and compliment the
 * setup in the following files:
 * 1. apps/bayada/tailwind.config.js
 * 2. libs/shared/mui/mui-config/src/lib/mui.theme.tsx
 */

$base: 0;
$xs: 576;
$sm: 767;
$md: 991;
$lg: 1200;
$xl: 1365;
$xxl: 1535;
$xxxl: 1800;
$fhd: 2160;
$qhd: 2560;
$kkk: 2880;
$uwqhd: 3440;
$kkkk: 3840;

:export {
  base: $base;
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
  xxl: $xxl;
  xxxl: $xxxl;
  fhd: $fhd;
  qhd: $qhd;
  kkk: $kkk;
  uwqhd: $uwqhd;
  kkkk: $kkkk;
}

$breakpoint-up: (
  base: (
    $base + 1 + 0px
  ),
  xs: (
    $xs + 1 + 0px
  ),
  sm: (
    $sm + 1 + 0px
  ),
  md: (
    $md + 1 + 0px
  ),
  lg: (
    $lg + 1 + 0px
  ),
  xl: (
    $xl + 1 + 0px
  ),
  xxl: (
    $xxl + 1 + 0px
  ),
  xxxl: (
    $xxxl + 1 + 0px
  ),
  fhd: (
    $fhd + 1 + 0px
  ),
  qhd: (
    $qhd + 1 + 0px
  ),
  kkk: (
    $kkk + 1 + 0px
  ),
  uwqhd: (
    $uwqhd + 1 + 0px
  ),
  kkkk: (
    $kkkk + 1 + 0px
  )
);

$breakpoint-down: (
  base: $base + 0px,
  xs: $xs + 0px,
  sm: $sm + 0px,
  md: $md + 0px,
  lg: $lg + 0px,
  xl: $xl + 0px,
  xxl: $xxl + 0px,
  xxxl: $xxxl + 0px,
  fhd: $fhd + 0px,
  qhd: $qhd + 0px,
  kkk: $kkk + 0px,
  uwqhd: $uwqhd + 0px,
  kkkk: $kkkk + 0px
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoint-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoint-down, $size)) {
    @content;
  }
}

@mixin orientation($direction) {
  $orientation-landscape: '(orientation:landscape)';
  $orientation-portrait: '(orientation:portrait)';

  @if $direction ==landscape {
    @media #{$orientation-landscape} {
      @content;
    }
  }

  @if $direction ==portrait {
    @media #{$orientation-portrait} {
      @content;
    }
  }
}
